@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    margin: 0;
    background-color: rgba(255, 255, 255, 1);
    color: #050505;
    position: fixed;
    top: 2rem;
    left: 34rem;
    right: 35rem;
    font-family: 'Comfortaa', cursive;
}
h1 {
    margin: 0;
}
.sign-out-btn {
    padding: 0.5rem 1rem;
    background-color: rgba(97, 218, 251, 0.15);
    cursor: pointer;
    border-radius: 20px;
    border-color: #4fa3d2;
}

.sign-out-btn:hover {
    background-color: #4fa3d2;
}
