.event-box {
    background-color: #e6f7ff;
    border-left: 5px solid rgba(3, 81, 134, 0.91);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.event-box h4 {
    margin-top: 0;
    margin-bottom: 5px;
}

.event-box p {
    margin: 5px 0;
}
.results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    margin: 20px;
}
.results {
    margin-top: 150px;
}
.results {
    padding-top: 100px;
}
.sentiment-chart-container {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    background-color: #f0f0f0;

}

.events {
    width: 100%;
    max-width: 1500px;
    font-size: 0.9em;
    font-family: 'Comfortaa', cursive;
    color: rgba(51, 51, 51, 0.91);

}

.event-box {
    background-color: white;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 10px;
}
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.affective-forecaster {
    font-family: 'Arial', sans-serif;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.24);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.input-group label {
    margin-bottom: 0.5rem;
    text-decoration: underline;
    text-decoration-color: #009cfd;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}


.container {
    text-align: center;
    z-index: 1;
    width: 60%;
}

.input-group {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}


button {
    background-color: rgba(68, 68, 68, 0.71);
    color: white;
    border: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    border-radius: 25px;
}

button:hover, button:focus {
    background-color: #555;
    transform: scale(1.05);
}

button:focus {
    outline: 2px solid #fff;
}

.new-scenario-container {
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

.new-scenario-btn {
    background-color: rgba(68, 68, 68, 0.71);
    color: white;
    border: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    border-radius: 25px;
    font-size: 12px;
}

.new-scenario-btn:hover,
.new-scenario-btn:focus {
    background-color: #555;
    transform: scale(1.05);
}

.new-scenario-btn:focus {
    outline: 2px solid #fff;
}

.updates-and-clear {
    position: relative;
    left: -5rem;
}

.personalization-updates {
    margin-bottom: 1rem;
    opacity: 0.3;
}

.clear-data-btn {
    padding: 0.5rem 1rem;
    background-color: rgba(97, 218, 251, 0.26);
    border: none;
    cursor: pointer;
    border-radius: 20px;
}

.clear-data-btn:hover {
    background-color: #4fa3d2;
    transform: scale(1.05);
}
.sentiment-analysis-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
    position: relative;
    z-index: 2;
}
