.circular-animation {
    width: 900px;
    height: 500px;
    margin-top: 3rem;
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 30%,
            rgba(255, 255, 255, 1) 70%,
            rgba(255, 255, 255, 0) 100%
    );
    /* Masking for fade-in and fade-out effect */
    /*-webkit-mask-image: linear-gradient(to right, transparent, black 15%, black 85%, transparent);*/
    mask-image: linear-gradient(to right, transparent, black 15%, black 85%, transparent);
}
