.processing-data {
    text-align: center;
    margin-top: 50px;
}

.processing-data h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.processing-data p {
    font-size: 18px;
    color: #666;
}
