@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.affective-forecaster-login {
    font-family: 'Arial', sans-serif;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.24);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.input-group-signup label {
    margin-bottom: 3rem;
    text-decoration: underline;
    text-decoration-color: #009cfd;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    font-family: 'Comfortaa', cursive;

}

.input-group-login label {
    margin-top: 5rem;
    margin-bottom: 3rem;
    text-decoration: underline;
    text-decoration-color: #009cfd;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    font-family: 'Comfortaa', cursive;

}
/*.input-group-signup, .input-group-login {*/
/*    margin-bottom: 4rem;*/
/*}*/

/*.input-group-signup .signup-btn, .input-group-login .login-btn {*/
/*    margin-top: 1rem;*/
/*}*/

.actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
}


.description {
    text-align: justify;
    font-family: 'Comfortaa', cursive;
    width: 100%;
    max-width: 60%;
    margin: 50px auto 3rem;
    padding: 1rem;
    box-sizing: border-box;
    font-size: 0.9rem;
}
.container {
    text-align: center;
    z-index: 1;
    width: 60%;
}

button {
    background-color: rgba(68, 68, 68, 0.71);
    color: white;
    border: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    border-radius: 25px;
}

button:hover, button:focus {
    background-color: #555;
    transform: scale(1.05);
}

button:focus {
    outline: 2px solid #fff;
}


.login-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 15vh;
}

.login-image {
    max-width: 40%;
}

.text-and-signup {
    max-width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
    width: 60%;
    margin: 0 auto;
}
