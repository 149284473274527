@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.affective-forecaster-login {
    font-family: 'Arial', sans-serif;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.24);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.input-group-signup label {
    margin-bottom: 3rem;
    text-decoration: underline;
    text-decoration-color: #009cfd;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    font-family: 'Comfortaa', cursive;

}

.input-group-login label {
    margin-top: 5rem;
    margin-bottom: 3rem;
    text-decoration: underline;
    text-decoration-color: #009cfd;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    font-family: 'Comfortaa', cursive;

}
/* careful this is being used on the login page as well */

.input-group-signup, .input-group-login {
    margin-bottom: 2rem;
}

.input-group-signup .signup-btn, .input-group-login .login-btn {
    margin-top: 1rem;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.description {
    text-align: justify;
    font-family: 'Comfortaa', cursive;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 70px;
    margin-bottom: 3rem;
}


.container {
    text-align: center;
    z-index: 1;
    width: 60%;
}

button {
    background-color: rgba(68, 68, 68, 0.71);
    color: white;
    border: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    border-radius: 25px;
}

button:hover, button:focus {
    background-color: #555;
    transform: scale(1.05);
}

button:focus {
    outline: 2px solid #fff;
}


.login-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 15vh;
}

.login-image {
    max-width: 40%;
    margin-right: -19rem;
}

.text-and-signup {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 1;
    width: 60%;
    margin: 0 auto;
}
.signin-signinpage-title {
    font-family: 'Comfortaa', cursive;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.form-section {
    width: 100%;
    max-width: 400px;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
}

.input-group label {
    font-family: 'Comfortaa', cursive;
    margin-bottom: 0.5rem;
}

.input-group input {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.login-button {
    margin-top: 1.5rem;
    width: 20%;
}
