@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.sentiment-chart-container {
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 20px;
    width: 85%;
    max-width: 800px;
    height: 400px;
    background-color: rgba(244, 244, 244, 0);
    border-radius: 10px;
}
