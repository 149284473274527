@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.affective-forecaster-home {
    font-family: 'Arial', sans-serif;
    color: #000000;
    background-color: rgba(255, 255, 255, 0.24);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.input-group-home label {
    margin-bottom: 0.5rem;
    text-decoration: underline;
    text-decoration-color: #009cfd;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    font-family: 'Comfortaa', cursive;

}
.container {
    text-align: center;
    z-index: 1;
    width: 80%;
}
.input-group {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Comfortaa', cursive;
}
.expectation-input, .scenario-input {
    width: 100%;
    overflow-y: auto;
    padding: 0.5rem;
    margin-top: 0.5rem;
    box-sizing: border-box;
    background-color: rgba(51, 51, 51, 0);
    color: #000000;
    resize: none;
    border-radius: 25px;
    transition: background-color 0.5s ease, border-color 0.5s ease;
    border: 1px solid rgba(17, 17, 72, 0);
}
.expectation-input:focus, .scenario-input:focus {
    background-color: rgba(22, 152, 208, 0.04);
    border: none;
    outline: none;
}
.expectation-input::placeholder, .scenario-input::placeholder {
    color: rgba(0, 0, 0, 0.24);
    transition: opacity 0.5s ease;
}
.expectation-input:focus::placeholder, .scenario-input:focus::placeholder {
    opacity: 0;
}
.expectation-input {
    height: 75px;
}
.scenario-input {
    height: 300px;
}
button {
    background-color: rgba(68, 68, 68, 0.71);
    color: white;
    border: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    border-radius: 25px;
}
button:hover, button:focus {
    background-color: #555;
    transform: scale(1.05);
}
button:focus {
    outline: 2px solid #fff;
}
.updates-and-clear-home {
    position: relative;
    left: -22vw;
}
.personalization-updates {
    margin-bottom: 1rem;
    opacity: 0.3;
}
.clear-data-btn {
    padding: 0.5rem 1rem;
    background-color: rgba(97, 218, 251, 0.26);
    border: none;
    cursor: pointer;
    border-radius: 20px;
}
.clear-data-btn:hover {
    background-color: #4fa3d2;
    transform: scale(1.05);
}

