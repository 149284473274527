.semi-circle-right {
    width: 41vw;
    height: 41vw;
    border: 1px solid #035186D8;
    border-radius: 50%;
    clip-path: circle(50% at 100% 50%);
    position: absolute;
    right: 5vw;
    /*-webkit-mask-image: linear-gradient(to bottom, transparent, #009cfd, transparent);*/
    mask-image: linear-gradient(to bottom, transparent, #035186D8, transparent);
}

.semi-circle-left {
    width: 41vw;
    height: 41vw;
    border: 1px solid #035186D8;
    border-radius: 50%;
    clip-path: circle(50% at 0 50%);
    position: absolute;
    left: 5vw;
    /*-webkit-mask-image: linear-gradient(to top, transparent, #009cfd, transparent);*/
    mask-image: linear-gradient(to top, transparent, #035186D8, transparent);
}
