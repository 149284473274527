@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 2vw;
    padding: 1rem 2% 1rem 4%;
    background-color: rgba(255, 255, 255, 1);
    color: #050505;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 50vw;
    /*margin: 0 auto;*/
    box-sizing: border-box;
    font-family: 'Comfortaa', cursive;
    z-index: 100;
    overflow: hidden;
}

.header::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgba(3, 81, 134, 0.45), transparent);
    width: 100%;
}

h1 {
    margin: 0;
    font-size: 1.5rem;
    white-space: nowrap;
}

.signin-button {
    background-color: rgba(68, 68, 68, 0.71);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    outline: none;
    border-radius: 15px;
    font-size: 12px;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    margin-left: 0rem;
    white-space: nowrap;
}

.signin-button:hover, .signin-button:focus {
    background-color: #555;
    transform: scale(1.05);
}

.signin-button:focus {
    outline: 2px solid #fff;
}




